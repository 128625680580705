import React, { useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, FormControl, 
  InputLabel, Select, MenuItem, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';

const url = process.env.REACT_APP_SERVER_URL;

const FileContentTable = ({ 
  dataFile, setColor, setMessage, setDataFile, setDisabledForm, setFile, setSelectedGroup, selectedGroup, file 
}) => {
  const [types, setTypes] = useState(Array(dataFile[0].length).fill('')); // Инициализация массива типов
  const [isLoading, setIsLoading] = useState(false); 

  const handleTypeChange = (event, columnIndex) => {
    const updatedTypes = [...types];
    updatedTypes[columnIndex] = event.target.value;
    setTypes(updatedTypes);
  };
  
  const importData = () => {

  if (!types.includes('phone')) {
      window.scrollTo(0, 0); 
      setColor('orange');
      setMessage('Укажите столбец с номером телефона');
      setTimeout(() => {
          setMessage('')
      }, 5000)
      return;
  }

  const formData = new FormData();
  formData.append('group', selectedGroup);
  formData.append('file', file);
  formData.append('types', JSON.stringify(types));
  setIsLoading(true);
  axios.post(`${url}/import/headers`, formData, {
    headers: {
        'jwt-token': localStorage?.access,
    }
  })
  .then(response => {
      if(response.status === 200) {
        setColor('green');
        setDataFile([])
        setDisabledForm(false)
        setFile(null)
        setSelectedGroup([])
        setMessage(response.data?.message);
        setTimeout(() => {
            setMessage('')
        }, 5000)
      } else {
          setColor('orange');
          setMessage(response.data?.message || 'Произошла ошибка');
          setTimeout(() => {
              setMessage('')
          }, 5000)
      }
  })
  .catch(error => {
      setColor('red');
          setMessage('Произошла ошибка');
          setTimeout(() => {
              setMessage('')
          }, 5000)
       
  })
  .finally(() => {
    window.scrollTo(0, 0);
    setIsLoading(false); // Сбрасываем состояние загрузки после получения ответа или ошибки
});
  };

  const options = [
    {title: 'Телефон', code: 'phone'},
    {title: 'ФИО', code: 'name'},
    {title: 'Примечание', code: 'note'},
    {title: 'Алиас', code: 'alias'},
    // {title: 'Дата рождения', code: 'bday'},
    {title: 'Переменная 1', code: 'customField1'},
    {title: 'Переменная 2', code: 'customField2'},
    {title: 'Переменная 3', code: 'customField3'},
    {title: 'Переменная 4', code: 'customField4'},
    {title: 'Переменная 5', code: 'customField5'},
    {title: 'Комментарий', code: 'dopInfo'}
  ]

  return (
    <>
    {isLoading ? ( 
       <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
       </div>
    ) : (  
      <>
      <div style={{ width: 'calc(100% - 50px)', overflowX: 'auto',  border: '1px solid #ccc', borderRadius: "2%" }}>
            <Table>
            <TableHead>
              <TableRow>
                {dataFile[0].map((cell, index) => (
                      <TableCell key={index}>
                      <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>Выберите тип</InputLabel>
                        <Select
                          label="Выберите тип"
                          value={types[index]}
                          onChange={(event) => handleTypeChange(event, index)}
                        >
                          <MenuItem value="">Выберите</MenuItem>
                          {options.map((option) => (
                            <MenuItem key={option.code} value={option.code} disabled={types.includes(option.code)}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataFile.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            </Table>
      </div>
        <Button color="primary" variant="contained" onClick={importData} style={{marginTop: '20px'}}>
          Импортировать
        </Button>
      </>
    )}
    </>
  );
};

export default FileContentTable;

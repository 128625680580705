import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
    Dialog, FormControl, InputLabel, Select, MenuItem,
    DialogTitle,
    DialogContent,
    DialogActions, TextField
} from '@mui/material';
import {Edit, Delete, Sms, Pause, PlayArrow} from '@mui/icons-material';
import axios from 'axios';
import './ListContact.css';

const url = process.env.REACT_APP_SERVER_URL;

const ListContact = ({updateContactList, allContact, listGroup, setMessage, setColor}) => {
    const navigate = useNavigate();

    const [deleteItem, setDeleteItem] = useState(null);
    const [editItem, setEditItem] = useState(null);
    const [editedData, setEditedData] = useState({
        groupId: [],
        name: '',
        phone: '',
        dopInfo: '',
        alias: '',
        note: '',
        customField1: '',
        customField2: '',
        customField3: '',
        customField4: '',
        customField5: '',
    });

    const pauseContact = (item, pause = true) => {
        axios.post(`${url}/contacts/pause`, {id: item.id, pause}, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then(response => {
                if (response.status === 200) {
                    updateContactList()
                    setColor('green')
                    setMessage(`Контакт "${item.phone}" временно исключен из рассылок`)
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                } else {
                    setColor('orange')
                    setMessage(`При приостановке ${item.phone} что то пошло не так`)
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                }
            })
            .catch(error => {
                setColor('red')
                setMessage(`При приостановке ${item.phone} произошла ошибка`)
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            });
    }

    const deleteContact = (item) => {
        setDeleteItem(null);
        axios.delete(`${url}/contacts/${item.id}`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then(response => {
                if (response.status === 200) {
                    updateContactList()
                    setColor('green')
                    setMessage(`Контакт "${item.phone}" удален`)
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                } else {
                    setColor('orange')
                    setMessage(`При удалении ${item.phone} что то пошло не так`)
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                }
            })
            .catch(error => {
                setColor('red')
                setMessage(`При удалении ${item.phone} произошла ошибка`)
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            });
        window.scrollTo(0, 0);
    }

    const handleGroupFilter = (id) => {

    }

    const handleSMS = (item) => {
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_SMS}/${item.phone.replace('+', '')}`)
    }

    const handleEdit = (item) => {
        setEditItem(item)
        setEditedData({
            groupId: (item.group) ? item.group.map(el => el.groupId) : [],
            name: item.name,
            phone: item.phone,
            dopInfo: item.dopInfo,
            alias: item.alias,
            note: item.note,
            customField1: item.customField1,
            customField2: item.customField2,
            customField3: item.customField3,
            customField4: item.customField4,
            customField5: item.customField5,
        })
    }

    const saveEdit = () => {
        axios.post(`${url}/contacts/editContact`, {...editedData, id: editItem.id}, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then(response => {
                if (response.status === 200) {
                    setColor('green')
                    setMessage(response.data?.message)
                    updateContactList()
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                } else {
                    setColor('orange')
                    setMessage(response.data?.message)
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                }
            })
            .catch(error => {
                setColor('red')
                setMessage(error.response.data?.err)
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            })
        window.scrollTo(0, 0);
        setEditItem(null);
    };

    const setPhone = (e) => {
        const phoneNumberRegex = /^[0-9]*$/;

        if (phoneNumberRegex.test(e.target.value) || e.target.value === '') {
            setEditedData({...editedData, phone: e.target.value})
        }
    }

    return (
        <>
            <p>Список контактов</p>
            {allContact?.length > 0 ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow className={'table_row_header'}>
                                    <TableCell>Имя</TableCell>
                                    <TableCell>Номер</TableCell>
                                    <TableCell>Группа</TableCell>
                                    <TableCell>Примечание</TableCell>
                                    <TableCell>Действия</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allContact.map((item) => {
                                    const arrayGroupId = item.group ? item.group.map(el => el.groupId) : [];
                                    const groupMap = {};
                                    listGroup.forEach(group => {
                                        groupMap[group.id] = group.title;
                                    });

                                    return (
                                        <TableRow key={item.id} className={'table_row'} style={{verticalAlign: 'top'}}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.phone}</TableCell>
                                            <TableCell>
                                                {arrayGroupId.map((id, index) => {
                                                    return (
                                                        <div key={id} className={"rounded_tag_name"}
                                                             onClick={() => handleGroupFilter(id)}>{groupMap[id]}</div>
                                                    )
                                                })}
                                            </TableCell>
                                            <TableCell>{item.note}</TableCell>
                                            <TableCell>
                                                <p><Button variant="contained" size="small" endIcon={<Edit/>}
                                                           onClick={() => handleEdit(item)}>
                                                    Редактировать
                                                </Button>
                                                </p>
                                                {!item.disabled ? (
                                                    <p><Button variant="contained" size="small" endIcon={<Sms/>}
                                                               onClick={() => handleSMS(item)}>
                                                        Отправить SMS
                                                    </Button></p>) : null}
                                                <p>
                                                    <Button variant="contained" size="small" color="error"
                                                            endIcon={<Delete/>}
                                                            onClick={() => setDeleteItem(item)}>Удалить</Button>
                                                </p>
                                                <p>
                                                    {!item.disabled ? (
                                                        <Button variant="contained" size="small"
                                                                endIcon={<Pause/>}
                                                                onClick={() => pauseContact(item, true)}>Приостановить</Button>) : (
                                                        <Button variant="contained" size="small"
                                                                endIcon={<PlayArrow/>}
                                                                onClick={() => pauseContact(item, false)}>Возобновить</Button>)}
                                                </p>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog open={Boolean(deleteItem)} onClose={() => setDeleteItem(null)}>
                        <DialogTitle>Вы точно хотите удалить контакт {deleteItem?.phone}?</DialogTitle>
                        <DialogContent>
                            <DialogActions>
                                <Button onClick={() => setDeleteItem(null)}>Нет</Button>
                                <Button onClick={() => deleteContact(deleteItem)} color="error">
                                    Да
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>

                    <Dialog open={Boolean(editItem)} onClose={() => setEditItem(null)}
                            style={{display: "flex", justifyContent: "space-around", width: '90%', marginTop: '-10px'}}>

                        <DialogTitle>Редактировать данные {editItem?.phone}</DialogTitle>
                        <DialogContent>

                            <TextField
                                fullWidth
                                margin="normal"
                                label="Имя"
                                type='text'
                                value={editedData.name}
                                onChange={(e) => setEditedData({...editedData, name: e.target.value})}
                            />

                            <TextField
                                fullWidth
                                margin="normal"
                                label="Номер телефона"
                                type='text'
                                value={editedData.phone}
                                onChange={setPhone}
                            />

                            <TextField
                                fullWidth
                                margin="normal"
                                label="Комментарий"
                                type='text'
                                value={editedData.dopInfo}
                                onChange={(e) => setEditedData({...editedData, dopInfo: e.target.value})}
                            />

                            <TextField
                                fullWidth
                                margin="normal"
                                label="Алиас"
                                type='text'
                                value={editedData.alias}
                                onChange={(e) => setEditedData({...editedData, alias: e.target.value})}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Примечание"
                                type='text'
                                value={editedData.note}
                                onChange={(e) => setEditedData({...editedData, note: e.target.value})}
                            />
                            <FormControl variant="outlined" fullWidth margin="normal">
                                <InputLabel>Выберите группу</InputLabel>
                                <Select
                                    label="Выберите группу"
                                    value={editedData.groupId}
                                    onChange={(e) => setEditedData({...editedData, groupId: e.target.value})}
                                    multiple
                                >
                                    {listGroup.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                fullWidth
                                margin="normal"
                                label="Переменная 1"
                                type='text'
                                value={editedData.customField1}
                                onChange={(e) => setEditedData({...editedData, customField1: e.target.value})}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Переменная 2"
                                type='text'
                                value={editedData.customField2}
                                onChange={(e) => setEditedData({...editedData, customField2: e.target.value})}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Переменная 3"
                                type='text'
                                value={editedData.customField3}
                                onChange={(e) => setEditedData({...editedData, customField3: e.target.value})}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Переменная 4"
                                type='text'
                                value={editedData.customField4}
                                onChange={(e) => setEditedData({...editedData, customField4: e.target.value})}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Переменная 5"
                                type='text'
                                value={editedData.customField5}
                                onChange={(e) => setEditedData({...editedData, customField5: e.target.value})}
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={saveEdit} color="primary">
                                Сохранить
                            </Button>
                            <Button onClick={() => setEditItem(null)}>Отмена</Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <p>Список пуст</p>
            )}
        </>
    );
};

export default ListContact;
import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import './Statistics.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ListStatistic from './ListStatistic/ListStatistic';

const url = process.env.REACT_APP_SERVER_URL;

const Statistics = () => {
    let navigate = useNavigate();
    const navigateTo = (path) => {
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${path}`, { replace: true })
    }
    
    useEffect (() => {
        if(localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if(localStorage?.expire < nowDate){
                navigateTo('login')
            } else {
                axios.post(`${url}/login/token`, localStorage)
                .then((response) => { 
                    if (response.data) {
                    } else {
                        navigateTo('login')
                    }
                })
                .catch((error) => {
                    // console.log(error)
                })
            }
        } else {
            navigateTo('login')
        }
    }, [])

    return (
        <div className='HeaderPage'>
        <Header/>
        <div className='StatisticTab'>
            <ListStatistic/>
        </div>
    </div>
    );
};

export default Statistics;
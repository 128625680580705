import React, {useState} from 'react';
import {Paper, TextField, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import './Login.css';

const url = process.env.REACT_APP_SERVER_URL;

const Login = () => {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [err, setErr] = useState('');
    let navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault();
        axios.post(`${url}/login`, {login, password})
            .then((response) => {
                if (response.status === 200) {
                    localStorage.phone = response.data.user?.login
                    localStorage.id = response.data.user.id
                    localStorage.status = response.data.user.status
                    localStorage.access = response.data.token.access
                    localStorage.expire = response.data.token.expire
                    navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_GROUPS}/contact`, {replace: true});
                } else {
                    setErr(response.data.err)
                    setTimeout(() => {
                        setErr('')
                    }, 5000)
                }
            })
            .catch((error) => {
                setErr('Произошла ошибка, попробуйте позже')
                setTimeout(() => {
                    setErr('')
                }, 5000)
            })
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <Paper class="login-container" elevation={3} style={{width: '30%', padding: '20px'}}>
                {err && <p style={{color: 'red'}}>{err}</p>}
                <div align={'center'}>
                    <img src={`${process.env.PUBLIC_URL}/mvk_sms_logo.png`} width="64px" height="64px"/>
                    <h2>Сервис SMS-рассылок</h2>
                </div>
                <form onSubmit={handleLogin} id='formLogin'>
                    <TextField
                        label="Имя пользователя"
                        type="string"
                        variant="outlined"
                        fullWidth
                        required
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        label="Пароль"
                        type="password"
                        variant="outlined"
                        fullWidth
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Войти
                    </Button>
                </form>
            </Paper>
        </div>
    );
};

export default Login;
import React, { useEffect } from 'react';
import Header from '../Header/Header';
import './SendSms.css'
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import FormSendSms from './FormSendSms/FormSendSms';

const url = process.env.REACT_APP_SERVER_URL;
const path = process.env.REACT_APP_FRONTEND_PATH;

const SendSms = () => {
    let navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${path}`, { replace: true })
    }

    useEffect (() => {
        if(localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if(localStorage?.expire < nowDate){
                navigateTo('login')
            } else {
                axios.post(`${url}/login/token`, localStorage)
                .then((response) => { 
                    if (response.data) {
                    } else {
                        navigateTo('login')        
                        // console.log('токена нет на сервере')
                    }
                })
                .catch((error) => {
                    // console.log(error)
                })
            }
        } else {
            navigateTo('login')            
            // console.log('нет токена на клиенте')
        }
    }, [])

    return (
        <div className='HeaderPage'>
            <Header/>
            <div className="SmsForm">
                <FormSendSms/>
            </div>
        </div>
    );
};

export default SendSms;
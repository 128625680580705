import React, {useEffect, useState} from 'react';
import {Button} from '@mui/material';
import './FilterStatistic.css';
import axios from "axios";

const url = process.env.REACT_APP_SERVER_URL;

const FilterStatistic = ({
                             setStartDate,
                             startDate,
                             setEndDate,
                             endDate,
                             updateSms,
                             setCurrentPage,
                             setParams,
                             users,
                             setUserFilterList,
                             setUserFilter
                         }) => {
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('');

    useEffect(() => {
        if (localStorage?.status === '1') {
            axios.get(`${url}/accounts/listUser`, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                        setUserFilterList(response.data)
                    } else {
                        setColor('red')
                        setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                        setTimeout(() => {
                            setMessage('')
                        }, 5000)
                    }
                })
                .catch((error) => {
                    setColor('red')
                    setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                });
        }
    }, [])

    const handleSearch = async (event) => {
        event.preventDefault();
        setCurrentPage(1)
        setParams(true)
        updateSms(true)
    }

    const handleStartDateChange = (e) => {
        const selectedStartDate = e.target.value;
        setStartDate(selectedStartDate);

        if (endDate && selectedStartDate > endDate) {
            setEndDate(selectedStartDate);
        }
    };

    const handleEndDateChange = (e) => {
        const selectedEndDate = e.target.value;

        if (selectedEndDate >= startDate) {
            setEndDate(selectedEndDate);
        }
    };

    const handleUserFilter = (e) => {
        setUserFilter(e.target.value)
    }

    const cleanFilter = () => {
        setCurrentPage(1)
        updateSms(false)
        setParams(false)
    }

    return (
        <div style={{marginTop: '10px'}}>
            {message ? <h3 style={{color: color}}>{message}</h3> : <></>}
            <form onSubmit={handleSearch} id='formSearch'>
                <div style={{display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}}>

                    <div className='formBlockDate'>
                        <div className='dateBlock'>
                            <label htmlFor="startDate">Выберите начальную дату:</label>
                            <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                value={startDate}
                                onChange={handleStartDateChange}
                            ></input>
                        </div>

                        <div className='dateBlock'>
                            <label htmlFor="endDate">Выберите конечную дату:</label>
                            <input
                                type="date"
                                id="endDate"
                                name="endDate"
                                value={endDate}
                                onChange={handleEndDateChange}
                            ></input>
                        </div>
                    </div>
                    {localStorage?.status === '1' ? <div className='formUserSelect'>
                        <label htmlFor="userSelect">Фильтр по пользователю:</label>
                        <select id="userSelect" name="userSelect" onChange={handleUserFilter}>
                            <option value="0">Все пользователи</option>
                            {
                                users.map((user) => {
                                    return <option key={user.id} value={user.id}>{user.login}</option>
                                })
                            }
                        </select>
                    </div> : <></>}

                    <div className='formBlockDate'>
                        <Button type="submit" color="primary" variant="contained"
                                sx={{display: "flex", marginRight: '2vw'}}>
                            Применить фильтр
                        </Button>
                        <Button sx={{display: "flex", marginLeft: '2vw'}} color="primary" variant="contained"
                                onClick={cleanFilter}>
                            Сбросить фильтр
                        </Button>
                    </div>
                </div>

            </form>

        </div>
    );
};

export default FilterStatistic;
import React, { useState } from 'react';
import './UserForm.css'
import { Button, TextField, MenuItem } from '@mui/material';
import axios from 'axios';
const url = process.env.REACT_APP_SERVER_URL;

const UserForm = ({ updateListUser }) => {
    const [message, setMessage] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [color, setColor] = useState('');
    const [role, setRole] = useState('0');

    const createUser = (event) => {
        event.preventDefault();

        if ( !password || !login) {
            setColor('red')
            setMessage('Заполните все данные')
            setTimeout(()=> {
                setMessage('')
            }, 5000)
        } else {
            axios.post(`${url}/accounts/createUser`, { login, password, role }, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
            .then((response) => {
                if(response.status === 201) {
                    setColor('green')
                    setMessage(response.data.message)
                    setTimeout(() => {
                        setMessage('')
                    }, 10000)
                    setPassword('')
                    setLogin('')
                    setRole('0')
                    updateListUser()
                } else {
                    setColor('orange')
                    setMessage(response.data.message)
                    setTimeout(() => {
                        setMessage('')
                    }, 7000)
                }
            })
            .catch((err) => {
                // console.log(err)
                setColor('red')
                setMessage('Произошла ошибка, попробуйте позже')
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            })
        }
    }
    
    return (
        <div style={{ position: 'relative', marginTop: '40px' }}>
            {message && (
                <h3 id="messageUsers" style={{ color: color }}>
                    {message}
                </h3>
            )}
            <form onSubmit={createUser} id='formUser' autoComplete="off">
            <h3 style={{ marginTop: '1px'}}>Форма создания пользователя</h3>

                <TextField onChange={(e) => setLogin(e.target.value)} name="formUserLogin" value={login}
                    sx={{margin: '10px', width: "50vw"}}
                    label="Ведите логин" className="outlined-size-normal" placeholder=""/>

                <TextField onChange={(e) => setPassword(e.target.value)} name="formUserPassword" value={password}
                    sx={{margin: '10px', width: "50vw"}} 
                    label="Ведите пароль" className="outlined-size-normal" placeholder=""/>

                <TextField
                    select
                    label="Выберите роль"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    sx={{ margin: '10px', width: "50vw" }}
                >
                    <MenuItem value="0">Пользователь</MenuItem>
                    <MenuItem value="1">Администратор</MenuItem>
                </TextField>

                <Button type="submit" sx={{margin: '20px'}} variant="contained" color="primary">
                    Создать пользователя
                </Button>

            </form>
        </div>
    );
};

export default UserForm;
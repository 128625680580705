import React, {useState, useEffect} from 'react';
import './Contacts.css'
import FormContact from './/FormContact/FormContact';
import ListContact from './ListContact/ListContact';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import {
    FormControl,
    InputLabel,
    Input,
    Select,
    MenuItem,
    Button,
    Checkbox,
    ListItemText,
    Chip,
    CircularProgress
} from '@mui/material';
import {useParams, useNavigate} from 'react-router-dom';

const url = process.env.REACT_APP_SERVER_URL;

const Contacts = ({setStateContacts}) => {
    const [allContact, setAllContact] = useState([]);
    const [color, setColor] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [listGroup, setListGroup] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Текущая страница
    const [pageSize, setPageSize] = useState(50); //кол-во эл на странице
    const [count, setCount] = useState(0);
    const [selectedGroup, setSelectorGroup] = useState([]);
    const {id} = useParams();
    const [searchText, setSearchText] = useState('');

    let navigate = useNavigate();
    const arrayToString = (arr) => arr?.join('&&');

    const navigateTo = (path) => {
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${path}`, {replace: true})
    };

    useEffect(() => {
        updateContactList();

        axios.get(`${url}/group/all`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    setListGroup(response.data)
                } else {
                    setColor('red')
                    setMessage('Произошла ошибка, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                }
            })
            .catch((error) => {
                setColor('red')
                setMessage('Произошла ошибка, попробуйте позже')
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            })
    }, []);

    useEffect(() => {
        if (!id) {
            navigateTo(`${process.env.REACT_APP_URL_GROUPS}/contact`)
            return
        }
        if (Number(id[0])) {
            updateContactList();
        }
    }, [id]);

    useEffect(() => {
        updateContactList();
    }, [currentPage]);

    const updateContactList = (params = true) => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const data = {startIndex, endIndex}
        if (Number(id) && params) {
            data.selectedGroup = [Number(id)]
        } else if (params) {
            data.selectedGroup = selectedGroup
        }

        data.searchText = searchText;

        axios.post(`${url}/contacts/all`, data, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    setAllContact(response.data.allContact)
                    setCount(response.data.count)
                    setLoading(false)
                } else {
                    setColor('orange')
                    setMessage('Произошла ошибка, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                }
            })
            .catch((error) => {
                setColor('red')
                setMessage('Произошла ошибка, попробуйте позже')
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            })
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    }

    const renderPagination = () => {
        const totalPages = Math.ceil(count / pageSize); // Рассчитываем общее количество страниц
        const maxButtons = 10; // Максимальное количество кнопок пагинации
        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2)); // Вычисляем начальную страницу
        let endPage = Math.min(totalPages, startPage + maxButtons - 1); // Вычисляем конечную страницу
        startPage = Math.max(1, endPage - maxButtons + 1); // Пересчитываем начальную страницу
        const pages = [...Array(endPage - startPage + 1).keys()].map(i => startPage + i); // Создаем массив номеров страниц для отображения

        return (
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                siblingCount={0} // Отключаем отображение кнопок prev/next
                boundaryCount={1} // Отключаем отображение первой и последней кнопок
            />
        );
    };

    const handleSearch = () => {
        updateContactList();
    };

    const handleCancelSearch = () => {
        navigateTo(`${process.env.REACT_APP_URL_GROUPS}/contact`)
        setSelectorGroup([])
        updateContactList(false)
    }

    return (
        <div style={{
            position: 'relative',
            marginTop: '60px',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "100vh"
        }}>
            {message ? <h3 style={{color: color}} id='messageContact'>{message}</h3> : <></>}

            <h3>Контакты</h3>
            <FormContact updateContactList={updateContactList} setMessage={setMessage} setColor={setColor}
                         listGroup={listGroup}/>
            <div style={{flexGrow: 1, width: '100%', marginTop: "20px"}}>
                {loading ? (
                    <CircularProgress/>
                ) : (
                    <>
                        <div className='seachDiv'>
                            <FormControl variant="outlined" style={{margin: '10px', width: '80%'}}>
                                <InputLabel>Выберите группу(ы)</InputLabel>
                                <Select
                                    label="Выберите группу(ы)"
                                    value={selectedGroup}
                                    onChange={(e) => setSelectorGroup(e.target.value)}
                                    multiple
                                    renderValue={(selected) => (
                                        <div>
                                            {selected.map((value) => (
                                                <Chip key={value} label={listGroup.find(el => el.id === value)?.title}/>
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={{PaperProps: {style: {maxHeight: 300}}}}
                                >
                                    {listGroup.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            <ListItemText primary={option.title}/>
                                            <Checkbox checked={selectedGroup.indexOf(option.id) > -1}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" style={{margin: '10px', width: '80%'}}>
                                <InputLabel>Поиск по текстовым полям</InputLabel>
                                <Input type='text' onBlur={handleSearchTextChange}/>
                            </FormControl>
                            <div className='buttonSearch'>
                                <Button variant="contained" onClick={handleSearch}>Применить</Button>
                                <Button variant="contained" onClick={handleCancelSearch}>Отменить фильтры</Button>
                            </div>
                        </div>
                        <ListContact allContact={allContact} updateContactList={updateContactList} listGroup={listGroup}
                                     setMessage={setMessage} setColor={setColor}/>
                    </>
                )}
            </div>
            <div style={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                width: '100%',
                padding: '10px',
                borderTop: '1px solid #ccc',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                {renderPagination()}
            </div>
        </div>
    );
};

export default Contacts;
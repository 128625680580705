import React from 'react';
import {Drawer, List, ListItem, ListItemText, ListItemIcon, Typography, Divider} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import MailIcon from '@mui/icons-material/Mail';
import SmsIcon from '@mui/icons-material/Sms';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from 'react-router-dom';


const Header = () => {

    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${path}`, {replace: true});
    };

    const exit = () => {
        localStorage.clear()
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/login`, {replace: true});
    }

    return (
        <Drawer
            variant="permanent"
            anchor="left"
        >
            <List>
                <img src={`${process.env.PUBLIC_URL}/mvk_sms_logo.png`} width="64px" height="64px"/>

                <Divider></Divider>

                <Typography noWrap fontSize={'10px'}>
                    Вы вошли как:&nbsp;
                    {
                        localStorage?.status === '1' ? 'Администратор' : 'Пользователь'
                    }
                </Typography>

                <Divider></Divider>

                <ListItem button onClick={() => navigateTo(process.env.REACT_APP_URL_GROUPS)}>
                    <ListItemIcon>
                        <GroupIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Группы и контакты"/>
                </ListItem>
                <ListItem button onClick={() => navigateTo(process.env.REACT_APP_URL_MAILING)}>
                    <ListItemIcon>
                        <MailIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Рассылка по группе"/>
                </ListItem>
                <ListItem button onClick={() => navigateTo(process.env.REACT_APP_URL_SMS)}>
                    <ListItemIcon>
                        <SmsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Отправить SMS"/>
                </ListItem>
                <ListItem button onClick={() => navigateTo(process.env.REACT_APP_URL_STATISTICS)}>
                    <ListItemIcon>
                        <BarChartIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Статистика"/>
                </ListItem>
                {localStorage?.status === '1' ? (
                    <ListItem button onClick={() => navigateTo(process.env.REACT_APP_URL_USERS)}>
                        <ListItemIcon>
                            <AccountBoxIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Пользователи"/>
                    </ListItem>
                ) : (
                    <></>
                )}
                <ListItem button onClick={() => exit()}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Выход"/>
                </ListItem>
            </List>
        </Drawer>
    );
};

export default Header;

import React, { useEffect, useState } from 'react';
import ListGroups from './ListGroups/ListGroups';
import FormGroups from './FormGroups/FormGroups';
import axios from 'axios';
import './Groups.css';
import Pagination from '@mui/material/Pagination';
import { CircularProgress } from '@mui/material';
const url = process.env.REACT_APP_SERVER_URL;

const Groups = () => {
    const [allGroup, setAllGroup] = useState([]);
    const [color, setColor] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1); // Текущая страница
    const [pageSize, setPageSize] = useState(20); //кол-во эл на странице
    const [count, setCount] = useState(0);

    useEffect(() => {
        updateListGroup()
    }, [])

    const updateListGroup = () => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;

        axios.post(`${url}/group/all`, { startIndex, endIndex }, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then((response) => { 
            if ( response.status === 200) {
                setAllGroup(response.data.groups)
                setCount(response.data.count)
                setLoading(false)
            } else {
                setColor('orange')
                setMessage(response.data?.message)
                setTimeout(()=> {
                    setMessage('')
                }, 5000)        
            }
        })
        .catch((error) => {
            setColor('red')
            setMessage('Произошла ошибка, попробуйте позже')
            setTimeout(()=> {
                setMessage('')
            }, 5000)
        })
    }

    useEffect(() => {
        updateListGroup();
    }, [currentPage]);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(count / pageSize); // Рассчитываем общее количество страниц
        const maxButtons = 10; // Максимальное количество кнопок пагинации
        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2)); // Вычисляем начальную страницу
        let endPage = Math.min(totalPages, startPage + maxButtons - 1); // Вычисляем конечную страницу
        startPage = Math.max(1, endPage - maxButtons + 1); // Пересчитываем начальную страницу
        const pages = [...Array(endPage - startPage + 1).keys()].map(i => startPage + i); // Создаем массив номеров страниц для отображения
    
        return (
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                siblingCount={0} // Отключаем отображение кнопок prev/next
                boundaryCount={1} // Отключаем отображение первой и последней кнопок
            />
        );
    };

    return (
        <>
        <div style={{ position: 'relative', marginTop: '60px', display: "flex", flexDirection: "column", alignItems: "center", minHeight: "100vh"   }}>
            {message ? <h3 style={{color: color}} id='messageGroup' >{message}</h3> : <></>}
            <h3>Группы контактов</h3>
                <FormGroups updateListGroup={updateListGroup} setColor={setColor} setMessage={setMessage}/>
                <div style={{ flexGrow: 1, width: '100%' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                        <ListGroups allGroup={allGroup} updateListGroup={updateListGroup} 
                        setColor={setColor} setMessage={setMessage} setAllGroup={setAllGroup}/>
                        </>
                    )}
                </div>
                <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', width: '100%', padding: '10px', borderTop: '1px solid #ccc',  display: "flex", flexDirection: "column", alignItems: "center", }}>
                    {renderPagination()}
                </div>
            </div>
        </>
    );
};

export default Groups;
import React, { useState } from 'react';
import './ListGroups.css'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogActions, TextField
  } from '@mui/material'; 
import axios from 'axios';
import { Link } from 'react-router-dom';
const url = process.env.REACT_APP_SERVER_URL;

const ListGroups = ({ allGroup, updateListGroup, setMessage, setColor}) => {
    const [DeleteItem, setDeleteItem] = useState(null);
    const [editItem, setEditItem] = useState(null);
    const [newTitle, setNewTitle] = useState('');
    const [newDopInfo, setNewDopInfo] = useState('');

    const handleEdit = () => {
        axios.post(`${url}/group/editGroup`, 
        {title: newTitle, id: editItem.id, dopInfo: newDopInfo}, 
        {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then(response => {
            if(response.status === 200) {
                setColor('green')
                setMessage('Данные успешно обновлены')
                updateListGroup()
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            } else {
                setColor('orange')
                setMessage("Что то пошло не так, попробуйте позже")
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            }
        })
        .catch(error => {
            setColor('red')
            setMessage("Произошла ошибка, попробуйте позже")
            setTimeout(()=> {
                setMessage('')
            }, 5000)
        })

        setEditItem(null);
    }

    const deleteGroup = (item) => {
        setDeleteItem(null);
        axios.delete(`${url}/group/${item.id}`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then(response => {
            if(response.status === 200) {
                updateListGroup()
                setColor('green')
                setMessage(`Группа "${item.title}" удалена`)
                setTimeout(()=> {
                    setMessage('')
                }, 5000) 
            } else {
                setColor('orange')
                setMessage(`При удалении ${item.title} что то пошло не так`)
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            }
        })
        .catch(error => {
            setColor('red')
            setMessage(`При удалении ${item.title} произошла ошибка`)
            setTimeout(()=> {
                setMessage('')
            }, 5000)
        });
    }

    const editData = (item) => {
        setNewTitle(item.title)
        setNewDopInfo(item.dopInfo)
        setEditItem(item)
    }

    return (
        <>
            <p>Список групп контактов:</p>
            {allGroup?.length > 0 ? (
            <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '40%' }}>Название</TableCell>
                                <TableCell>Редактировать</TableCell>
                                <TableCell>Удалить</TableCell>
                                <TableCell>Комментарий</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allGroup.map((item) =>  {
                                return (
                                    <TableRow key={item.id}>
                                    <TableCell>
                                        <Link to={`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_GROUPS}/contact/${item.id}`}
                                        style={{ width: '40%' }}>
                                            {item.title}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => editData(item)}>Изменить</Button>
                                    </TableCell>
                                    <TableCell>
                                    <Button onClick={() => setDeleteItem(item)}>Удалить</Button>
                                    </TableCell>
                                    <TableCell>
                                        {item.dopInfo}
                                    </TableCell>
                                    </TableRow>
                                ) 
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog open={Boolean(DeleteItem)} onClose={() => setDeleteItem(null)}>
                    <DialogTitle>Вы точно хотите удалить группу "{DeleteItem?.title}" и все контакты с этой группы?</DialogTitle>
                    <DialogContent>
                        <DialogActions>
                            <Button onClick={() => setDeleteItem(null)}>Нет</Button>
                            <Button onClick={() => deleteGroup(DeleteItem)} color="error">
                                Да
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>

                <Dialog open={Boolean(editItem)} onClose={() => setEditItem(null)}>
                    <DialogTitle>Редактировать данные "{editItem?.title}"</DialogTitle>
                    <DialogContent>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Название"
                        type='text'
                        value={newTitle}
                        onChange={(e) => setNewTitle( e.target.value)}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        label="Комментарий"
                        type='text'
                        value={newDopInfo}
                        onChange={(e) => setNewDopInfo( e.target.value)}
                    />

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={handleEdit} color="primary" sx={{marginRight: '10px'}}>
                            Сохранить
                        </Button>
                        <Button onClick={() => setEditItem(null)} sx={{marginLeft: '10px'}}>Отменить</Button>
                    </div>
                    </DialogContent>
                </Dialog>
            </>
            ) : (
                <p>Список пуст</p>
            )}
        </>
    );
};

export default React.memo(ListGroups);
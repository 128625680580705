import React, {useState} from 'react';
import './FormSendSms.css'
import axios from 'axios';
import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    InputLabel,
    Select, Chip, MenuItem, ListItemText, Checkbox, FormControl
} from '@mui/material';
import {formatter, handlePhoneChange} from '../../../utils/phoneNumber.mjs';
import FormTextSms from '../../FormTextSms/FormTextSms';
import {useParams} from "react-router-dom";

const url = process.env.REACT_APP_SERVER_URL;

const FormSendSms = () => {
    const [text, setText] = useState('');
    const [phone, setPhone] = useState('');
    const [color, setColor] = useState('');
    const [message, setMessage] = useState('');
    const [stateButtonSend, setStateButtonSend] = useState(true);
    const [showContactListDialog, setShowContactListDialog] = useState(false);

    let {phoneNum} = useParams();

    if (!phoneNum) {
        phoneNum = '';
    } else {
        if (!phone) {
            setPhone(phoneNum);
        }
    }

    const newSms = (event) => {
        event.preventDefault();

        if (!text) {
            setMessage(`Введите текст для отправки смс`)
            setColor('red')
            setTimeout(() => {
                setMessage('')
            }, 5000)
            return
        }
        const checkPhone = formatter(phone)

        if (!checkPhone) {
            setMessage(`Проверьте корректность введенного номера ${phone}`)
            setColor('red')
            setTimeout(() => {
                setMessage('')
            }, 5000)
        } else {
            axios.post(`${url}/sms/newSms`, {text, phone: checkPhone}, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        setColor('green')
                        setMessage("Сообщение принято")
                        setTimeout(() => {
                            setMessage('')
                        }, 10000)
                        setText('')
                        setPhone('')
                    }
                })
                .catch((err) => {
                    // console.log(err)
                    setColor('red')
                    setMessage('Произошла ошибка, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                })
        }
    }

    return (
        <div style={{position: 'relative', marginTop: '60px'}}>
            {message ? <h3 style={{color: color}} id='messageSms'>{message}</h3> : <></>}
            <form onSubmit={newSms} id='formSms' autoComplete="off">
                <h3 style={{marginTop: '1px'}}>Форма отправки SMS</h3>

                <TextField onChange={(e) => handlePhoneChange(e, setPhone)} name="formPhoneSms"
                           value={phone ? phone : phoneNum}
                           sx={{margin: '10px', width: "50vw"}}
                           label="Ведите номер телефона" className="outlined-size-normal"
                           placeholder="Введите номер телефона в формате: 7ХХХХХХХХХХ (только цифры)"
                />

                {
                    <Dialog open={showContactListDialog}>
                        <DialogTitle>Выберите контакт</DialogTitle>
                        <DialogContent>
                            <DialogContentText>

                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                }

                <FormTextSms setText={setText} setStateButtonSend={setStateButtonSend} text={text}/>

                <Button type="submit" sx={{margin: '20px'}} variant="contained" color="primary"
                        disabled={!stateButtonSend}>
                    Отправить сообщение
                </Button>

            </form>
        </div>
    );
};

export default FormSendSms;
import React, { useState } from 'react';
import { Button, Modal, TextField } from '@mui/material';
import axios from 'axios';
import './FormGroups.css'
const url = process.env.REACT_APP_SERVER_URL;

const FormGroups = ({ updateListGroup, setColor, setMessage }) => {
    const [open, setOpen] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [dopInfo, setDopInfo] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreate = () => {
        if(!groupName) {
            setColor('red');
            setMessage('Напишите название группы');
            setTimeout(()=> {
                setMessage('');
            }, 5000);
            return 
        }
        axios.post(`${url}/group/newGroup`, { title: groupName, dopInfo }, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then((response) => { 
            if(response.status === 200) {
                setGroupName('');
                setDopInfo('');
                updateListGroup();
                setColor('green');
                setMessage(`Группа "${groupName}" добавлена`);
                setTimeout(()=> {
                    setMessage('');
                }, 5000);
            } else {
                setGroupName('');
                setDopInfo('');
                updateListGroup();
                setColor('orange');
                setMessage(response.data?.message || 'Произошла ошибка');
                setTimeout(()=> {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            setColor('red');
            setMessage('Произошла ошибка, попробуйте позже');
            setTimeout(()=> {
                setMessage('');
            }, 5000);
        })
        handleClose();
    };

    return (
        <>
            <Button variant="contained" onClick={handleOpen}>Добавить новую группу</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={{ position: 'absolute', top: '50%', left: '60%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px' }}>
                    <h2>Создание группы</h2>
                    <TextField
                        label="Название группы"
                        value={groupName}
                        type='string'
                        onChange={(e) => setGroupName(e.target.value)}
                        fullWidth
                        margin="normal"
                    />

                    <TextField
                        label="Комментарий"
                        value={dopInfo}
                        type='string'
                        onChange={(e) => setDopInfo(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button variant="contained" color="primary" onClick={handleCreate}>Создать</Button>
                        <Button variant="contained" onClick={handleClose} style={{ marginLeft: '10px' }}>Отменить</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default FormGroups;
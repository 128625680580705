import libphonenumber from 'google-libphonenumber';
const {PhoneNumberFormat: PNF, PhoneNumberUtil} = libphonenumber;
const phoneUtil = PhoneNumberUtil.getInstance();
const maxLength = 12;
const minLength = 10;

export function formatter(str, locale='ru') {
    try {
        const text = str.replace(/\s/g, '');

        if (text.length < minLength || text.length > maxLength) {
            return false
        } else if ( !/^\+?[0-9]+$/.test(text)){
            return false
        }
        const number = phoneUtil.parseAndKeepRawInput(text, locale);
        if (number.values_['2'].toString().length == 10) {
            return `${number.values_['1']}${number.values_['2']}` //79198182565
        } else {
            return false
        }
    } catch (error) {
        // console.log(error) // если номер начинается с +8
        return false
    }
}

export function handlePhoneChange (e, setPhone) {
    const phoneNumberRegex = /^[0-9]*$/;

    if (phoneNumberRegex.test(e.target.value) || e.target.value === '') {
        setPhone(e.target.value);
    }
};

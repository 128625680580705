import React from 'react';
import { TextField } from '@mui/material';
import { useState } from 'react';

const FormTextSms = ({ setText, setStateButtonSend, text }) => {

    const [smsCount, setSmsCount] = useState(0);

    const handleTextChange = (e) => {
        const inputText = e.target.value;
        const count = Math.ceil(inputText.length / 70)
        setSmsCount(count)
        if(count > 20) {
            setStateButtonSend(false)
        } else {
            setStateButtonSend(true)
        }
        const parts = [];

        for (let i = 0; i < inputText.length; i += 70) {
            parts.push(inputText.substring(i, i + 70));
        }

        setText(e.target.value); 
    };

    return (
        <div>
            <TextField
                onChange={handleTextChange}
                value={text}
                label="Введите текст"
                multiline
                variant="outlined"
                sx={{ margin: '10px', width: '50vw' }}
                maxRows={8}
            />
            {text.length > 0 && (
                <div>
                    Количество SMS: {smsCount}
                    {smsCount > 20 && (
                        <div style={{ color: 'red' }}>
                            Нельзя отправить больше 20 сообщений
                        </div>
                    )}
                </div>
            )}

        </div>
    );
};

export default FormTextSms;

import './App.css';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import GroupsContacts from './components/GroupsContacts/GroupsContacts';
import Mailing from './components/Mailing/Mailing';
import SendSms from './components/SendSms/SendSms';
import Statistics from './components/Statistics/Statistics';
import Users from './components/Users/Users';
import ImportContact from './components/ImportContact/ImportContact';

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/login`} element={<Login/>}/>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_GROUPS}`} element={<GroupsContacts/>}/>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_GROUPS}/contact/:id`} element={<GroupsContacts/>}/>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_GROUPS}/:id`} element={<GroupsContacts/>}/>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_MAILING}`} element={<Mailing/>}/>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_SMS}`} element={<SendSms/>}/>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_SMS}/:phoneNum`} element={<SendSms/>}/>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_STATISTICS}`} element={<Statistics/>}/>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_URL_USERS}`} element={<Users/>}/>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/${process.env.REACT_APP_IMPORT}`} element={<ImportContact/>}/>
          <Route path={`${process.env.REACT_APP_FRONTEND_PATH}/*`} element={<Login/>}/>
          {/* <Route path={`/login`} element={<Login/>}/>
          <Route path={`/${process.env.REACT_APP_URL_GROUPS}`} element={<GroupsContacts/>}/>
          <Route path={`/${process.env.REACT_APP_URL_MAILING}`} element={<Mailing/>}/>
          <Route path={`/${process.env.REACT_APP_URL_SMS}`} element={<SendSms/>}/>
          <Route path={`/${process.env.REACT_APP_URL_STATISTICS}`} element={<Statistics/>}/>
          <Route path={`/${process.env.REACT_APP_URL_USERS}`} element={<Users/>}/>
          <Route path={`/*`} element={<Login/>}/> */}
      </Routes>
    </div>
  );
}

export default App;

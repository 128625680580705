import React, { useState } from 'react';
import { TextField, Button,  FormControl, InputLabel, MenuItem, Select, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './FormContact.css'
import { formatter, handlePhoneChange } from '../../../utils/phoneNumber.mjs';
const url = process.env.REACT_APP_SERVER_URL;

const FormContact = ({ updateContactList, setColor, setMessage, listGroup }) => {
    let navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${path}`, { replace: true })
    }
    const [open, setOpen] = useState(false);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [group, setGroup] = useState([]);
    const [messageError, setMessageError] = useState('');
    const [dopInfo, setDopInfo] = useState('');
    const [alias, setAlias] = useState('');
    const [note, setNote] = useState('');
    const [customField1, setCustomField1] = useState('');
    const [customField2, setCustomField2] = useState('');
    const [customField3, setCustomField3] = useState('');
    const [customField4, setCustomField4] = useState('');
    const [customField5, setCustomField5] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleImport = () => {
        navigateTo(process.env.REACT_APP_IMPORT)
    };

    const handleCreate = () => {
        const checkPhone = formatter(phone, 'ru')

        if (!checkPhone) {
            setMessageError(`Неверный или неполный номер ${phone}`)
            setTimeout(()=> {
                setMessageError('')
            }, 5000) 
        } else {
            axios.post(`${url}/contacts/newContact`, 
            { name, phone: checkPhone, group, dopInfo, alias, note, customField1, customField2, customField3, customField4, customField5 }, 
            {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
            .then((response) => { 
                if ( response.status === 200) {
                    setName('')
                    setPhone('')
                    setGroup([])
                    updateContactList()
                    setColor('green')
                    setMessageError('')
                    setMessage(`Контакт "${checkPhone}" добавлен`)
                    setTimeout(()=> {
                        setMessage('')
                    }, 5000)  
                } else {
                    setColor('red')
                    setMessage(response.data.message)
                    setTimeout(()=> {
                        setMessage('')
                    }, 5000)  
                }
            })
            .catch((error) => {
                setColor('red')
                    setMessage('ошибка при создании контакта')
                    setTimeout(()=> {
                        setMessage('')
                    }, 5000) 
            })
            handleClose();
        }
    };

    return (
        <div style={{ position: 'relative', display: "flex", justifyContent: "space-around",width: '90%', flexDirection: "column", marginTop: '60px',}}>
            {messageError ? (<h3 id='message'>{messageError}</h3>):(<></>)}

            <div style={{ display: "flex", justifyContent: "space-around",width: '90%', marginTop: '-10px' }}>
                <Button variant="contained" onClick={handleOpen}>Добавить новый контакт</Button>
                <Button variant="contained" onClick={handleImport}>Импорт контактов</Button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div 
                    style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: '20px',
                    width: '80%',
                    height: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                // style={{ position: 'absolute', top: '50%', left: '60%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px' }}
                >
                    <h2>Создание контакта</h2>
                    {messageError ? (<h3 style={{color: 'red', marginTop: '-25px'}} >{messageError}</h3>):(<></>)}
                    <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ flex: 1, marginRight: '10px' }}>
                    <TextField
                        label="Введите ФИО"
                        value={name}
                        type='string'
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                        style={{ width: '40vw' }} 
                    />

                    <TextField
                        label="Введите номер телефона"
                        value={phone}
                        type='string'
                        onChange={(e) => handlePhoneChange(e, setPhone)}
                        fullWidth
                        placeholder="Введите номер телефона в формате: 7ХХХХХХХХХХ (только цифры)"
                        margin="normal"
                        style={{ width: '40vw' }} 
                    />

                    <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel>Выберите группу</InputLabel>
                    <Select
                    label="Выберите группу"
                    value={group}
                    onChange={(e) => setGroup([...e.target.value])} 
                    multiple
                    style={{ width: '40vw' }} 
                    >
                        {listGroup.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.title}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>

                    <TextField
                        label="Комменатрий"
                        value={dopInfo}
                        type='string'
                        onChange={(e) => setDopInfo(e.target.value)}
                        fullWidth
                        margin="normal"
                        style={{ width: '40vw' }} 
                    />

                    <TextField
                        label="Алиас"
                        value={alias}
                        type='string'
                        onChange={(e) => setAlias(e.target.value)}
                        fullWidth
                        margin="normal"
                        style={{ width: '40vw' }} 
                    />

                    <TextField
                        label="Примечание"
                        value={note}
                        type='string'
                        onChange={(e) => setNote(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    </div>
                    <div style={{ flex: 1, marginLeft: '10px' }}>
                    <TextField
                        label="Переменная 1"
                        value={customField1}
                        type='string'
                        onChange={(e) => setCustomField1(e.target.value)}
                        fullWidth
                        margin="normal"
                    />

                    <TextField
                        label="Переменная 2"
                        value={customField2}
                        type='string'
                        onChange={(e) => setCustomField2(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Переменная 3"
                        value={customField3}
                        type='string'
                        onChange={(e) => setCustomField3(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Переменная 4"
                        value={customField4}
                        type='string'
                        onChange={(e) => setCustomField4(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Переменная 5"
                        value={customField5}
                        type='string'
                        onChange={(e) => setCustomField5(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    </div>
                    </div>
                    <div>
                        <Button variant="contained" color="primary" onClick={handleCreate}>Создать</Button>
                        <Button variant="contained" onClick={handleClose} style={{ marginLeft: '10px' }}>Отменить</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default FormContact;
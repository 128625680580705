import React, {useEffect, useState} from 'react';
import moment from 'moment';
import './ListStatistic.css'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress} from '@mui/material';
import axios from 'axios';
import FilterStatistic from '../FilterStatistic/FilterStatistic';
import Pagination from '@mui/material/Pagination';

const url = process.env.REACT_APP_SERVER_URL;

const ListStatistic = () => {
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('');
    const [allSms, setAllSms] = useState([]);
    const currentDate = new Date();
    const [startDate, setStartDate] = useState(currentDate.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(currentDate.toISOString().split('T')[0]);
    const [currentPage, setCurrentPage] = useState(1); // Текущая страница
    const [pageSize, setPageSize] = useState(20); //кол-во эл на странице
    const [count, setCount] = useState(0);
    const [params, setParams] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userFilterList, setUserFilterList] = useState([]);
    const [userFilter, setUserFilter] = useState(0);

    useEffect(() => {
        updateSms()
    }, [])

    useEffect(() => {
        updateSms(params);
    }, [currentPage]);

    useEffect(() => {
        updateSms(params);
    }, [userFilter]);

    const updateSms = (arg = false) => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        let data = {
            startIndex,
            endIndex,
            userFilter
        }

        if (arg) {
            data = {
                ...data,
                startDate,
                endDate
            }
        }

        axios.post(`${url}/statistic/statistic`, data,
            {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
            .then((response) => {
                setAllSms(response.data.allSms)
                setCount(response.data.count)
                setLoading(false)
            })
            .catch((error) => {
                setColor('red')
                setMessage(error.response.data.err)
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            })
    }

    const checkStatus = (status) => {
        switch (status) {
            case 'QUEUED':
                return 'В ОЧЕРЕДИ';
            case 'IN_PROGRESS':
                return 'ОТПРАВЛЯЕТСЯ';
            case 'SENT':
                return 'ОТПРАВЛЕНО';
            case 'DELIVERED':
                return 'ДОСТАВЛЕНО';
            case 'FAILED':
                return 'ОШИБКА';
            default:
                return 'НЕ ОПРЕДЕЛЕНО';
        }
    }

    const getStatusStyle = (status) => {
        switch (status) {
            case 'QUEUED':
                return {color: 'black'};
            case 'IN_PROGRESS':
                return {color: 'black'};
            case 'SENT':
                return {color: 'black'};
            case 'DELIVERED':
                return {color: 'green'};
            case 'FAILED':
                return {color: 'red'};
            default:
                return {color: 'black'};
        }
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(count / pageSize); // Рассчитываем общее количество страниц
        const maxButtons = 10; // Максимальное количество кнопок пагинации
        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2)); // Вычисляем начальную страницу
        let endPage = Math.min(totalPages, startPage + maxButtons - 1); // Вычисляем конечную страницу
        startPage = Math.max(1, endPage - maxButtons + 1); // Пересчитываем начальную страницу
        const pages = [...Array(endPage - startPage + 1).keys()].map(i => startPage + i); // Создаем массив номеров страниц для отображения

        return (
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                siblingCount={0} // Отключаем отображение кнопок prev/next
                boundaryCount={1} // Отключаем отображение первой и последней кнопок
            />
        );
    };

    return (
        <div style={{
            position: 'relative',
            marginTop: '60px',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "100vh"
        }}>
            {message ? <h3 style={{color: color}}>{message}</h3> : <></>}
            <h3>Статистика</h3>
            <FilterStatistic
                setStartDate={setStartDate}
                startDate={startDate}
                setEndDate={setEndDate}
                endDate={endDate}
                updateSms={updateSms}
                users={userFilterList}
                setCurrentPage={setCurrentPage}
                setParams={setParams}
                setUserFilterList={setUserFilterList}
                setUserFilter={setUserFilter}
            />
            <div style={{flexGrow: 1, width: '100%'}}>
                {loading ? (
                    <CircularProgress/>
                ) : (
                    allSms?.length > 0 ? (
                        <div style={{marginTop: '10px', width: '100%'}}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {localStorage?.status === '1' ? <TableCell>Пользователь</TableCell> : <></>}
                                            <TableCell>Номер телефона</TableCell>
                                            <TableCell style={{width: '20%'}}>Текст</TableCell>
                                            <TableCell>Группа</TableCell>
                                            <TableCell>Дата создания</TableCell>
                                            <TableCell>Дата обновления</TableCell>
                                            <TableCell>Статус</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {allSms.map((item) => {
                                            const status = checkStatus(item.status);
                                            const statusStyle = getStatusStyle(item.status);
                                            const dateCreated = moment(item.createdAt).format('DD.MM.YYYY HH:mm:ss')
                                            const dateUpdated = moment(item.updatedAt).format('DD.MM.YYYY HH:mm:ss')
                                            return (
                                                <TableRow key={item.id}>
                                                    {localStorage?.status === '1' ?
                                                        <TableCell>{item.ownerName}</TableCell> : <></>}
                                                    <TableCell>{item.phone} {item.contactName ? '(' + item.contactName + ')' : '' }</TableCell>
                                                    <TableCell style={{width: '20%'}}>{item.text}</TableCell>
                                                    <TableCell>{item.groupId && item.group?.title}</TableCell>
                                                    <TableCell>{dateCreated}</TableCell>
                                                    <TableCell>{dateUpdated}</TableCell>
                                                    <TableCell style={statusStyle}>{status}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    ) : (
                        <p>Список пуст</p>
                    )
                )}
            </div>
            <div style={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                width: '100%',
                padding: '10px',
                borderTop: '1px solid #ccc',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                {renderPagination()}
            </div>
        </div>
    );
};

export default React.memo(ListStatistic);


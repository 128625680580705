import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import './ImportContact.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button,  FormControl, InputLabel, MenuItem, Select, Input } from '@mui/material';
import FileContentTable from './FileContentTable/FileContentTable';
import HeaderGroupContact from '../GroupsContacts/HeaderGroupContact/HeaderGroupContact';

const url = process.env.REACT_APP_SERVER_URL;

const ImportContact = () => {
    let navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('');
    const [listGroup, setListGroup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [file, setFile] = useState(null);
    const [dataFile, setDataFile] = useState([]);
    const [disabledForm, setDisabledForm] = useState(false);


    const navigateTo = (path) => {
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${path}`, { replace: true })
    };

    useEffect (() => {
        if(localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if(localStorage?.expire < nowDate){
                navigateTo('login')
            } else {
                axios.post(`${url}/login/token`, localStorage)
                .then((response) => { 
                    if (response.data) {
                        axios.get(`${url}/group/all`, {
                            headers: {
                                'jwt-token': localStorage?.access,
                            }
                        })
                        .then((response) => { 
                            if ( response.status === 200) {
                                setListGroup(response.data)
                            } else {
                                setColor('red')
                                setMessage('Произошла ошибка, попробуйте позже')
                                setTimeout(()=> {
                                    setMessage('')
                                }, 5000)        
                            }
                        })
                        .catch((error) => {
                            setColor('red')
                            setMessage('Произошла ошибка, попробуйте позже')
                            setTimeout(()=> {
                                setMessage('')
                            }, 5000)
                        })
                    } else {
                        navigateTo('login')                    
                    }
                })
                .catch((error) => {
                    // console.log(error)
                })
            }
        } else {
            navigateTo('login')   
             // console.log('нет токена на клиенте')
        }
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedGroup.length === 0) {
            setColor('red');
            setMessage('Выберите группу');
            setTimeout(()=> {
                setMessage('')
            }, 5000)
            return;
        }
        if (!file) {
            setColor('red');
            setMessage('Выберите файл');
            setTimeout(()=> {
                setMessage('')
            }, 5000)
            window.scrollTo(0, 0); 
            return;
        }
        const formData = new FormData();
        formData.append('group', selectedGroup);
        formData.append('file', file);

        axios.post(`${url}/import/file`, formData, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then(response => {
            if(response.status === 200) {
                setDataFile(response.data)
                setDisabledForm(true)
            } else {
                setColor('orange');
                setMessage(response.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            }
        })
        .catch(error => {
            setColor('red');
                setMessage('Произошла ошибка');
                setTimeout(() => {
                    setMessage('')
                }, 5000)
        })
    };

    return (
      <div className='HeaderPage'>
            <Header />
            <HeaderGroupContact stateContacts={true}/>
            <div className="SendingForm" style={{marginLeft: '250px'}}>
                <div style={{ position: 'relative', marginTop: '60px', display: "flex", flexDirection: "column", alignItems: "center", minHeight: "100vh" }}>
                    {message ? <h3 style={{ color: color }} id='messageContact' >{message}</h3> : <></>}
                    <h3>Импорт контакт</h3>
                    <form onSubmit={handleSubmit}>
                        <div>
                        <FormControl variant="outlined" fullWidth margin="normal" disabled={disabledForm}>
                            <InputLabel>Выберите группу</InputLabel>

                            <Select
                            label="Выберите группу"
                            value={selectedGroup}
                            onChange={(e) => setSelectedGroup([...e.target.value])} 
                            multiple
                            disabled={disabledForm}
                            >
                                {listGroup.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </div>
                        <div>
                            <InputLabel htmlFor="file">Выберите файл (CSV или XLSX):</InputLabel>
                            <Input
                                id="file"
                                type="file"
                                onChange={handleFileChange}
                                inputProps={{ accept: '.csv, .xlsx' }}
                                value={file ? file?.File?.name : ''}
                            />
                        </div>
                        <div>
                            <Button type="submit" color="primary" variant="contained" disabled={disabledForm}>
                                Загрузить
                            </Button>
                        </div>
                    </form>

                    {dataFile.length > 0 ? (
                        <FileContentTable dataFile={dataFile} setColor={setColor} setMessage={setMessage} 
                        setDataFile={setDataFile} setDisabledForm={setDisabledForm} setFile={setFile} 
                        setSelectedGroup={setSelectedGroup} file={file} selectedGroup={selectedGroup}/>
                    ):(
                        <></>
                    )}
                </div>
            </div>
      </div>
    );
};

export default ImportContact;

import React from 'react';
import './HeaderGroupContact.css'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const HeaderGroupContact = ({ stateContacts }) => {
    let navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${path}`, { replace: true })
    }
    const changeBlock = (path) => {
        // setStateContacts(boolean)
        navigateTo(`${process.env.REACT_APP_URL_GROUPS}/${path}`)
    }

    return (
        <div className='divButtonContactsGroup'>
            <Button onClick={() => changeBlock('contact')}    style={{ 
                    marginRight: '10px', 
                    color: 'white', 
                    // textDecoration: stateContacts ? 'underline white' : 'none', 
                    textShadow: stateContacts ? '0 0 10px white' : 'none',
                    border: stateContacts ? '2px solid white' : 'none',
                    boxShadow: stateContacts ? '0 0 10px white' : 'none',
                }}>
                Контакты
            </Button>
            <Button onClick={() => changeBlock('group')}     style={{ 
                    marginLeft: '10px', 
                    color: 'white', 
                    // textDecoration: !stateContacts ? 'underline white' : 'none', 
                    textShadow: !stateContacts ? '0 0 10px white' : 'none',
                    border: !stateContacts ? '2px solid white' : 'none',
                    boxShadow: !stateContacts ? '0 0 10px white' : 'none',
                }}>
                Группы контактов
            </Button>
        </div>
    );
};

export default HeaderGroupContact;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import './GroupsContacts.css'
import Contacts from '../Contacts/Contacts';
import Groups from '../Groups/Groups';
import axios from 'axios';
import HeaderGroupContact from './HeaderGroupContact/HeaderGroupContact';

const url = process.env.REACT_APP_SERVER_URL;

const GroupsContacts = () => {
    const [stateContacts, setStateContacts] = useState(true);
    let navigate = useNavigate();
    const { id } = useParams();

    const navigateTo = (path) => {
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${path}`, { replace: true })
    }

    useEffect (() => {
        if(localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if(localStorage?.expire < nowDate){
                navigateTo('login')
            } else {
                axios.post(`${url}/login/token`, localStorage)
                .then((response) => { 
                    if (response.data) {
                        if(!id) {
                            changeBlock('contact')
                            return
                        }

                        if ( id === 'group') {
                            setStateContacts(false)
                        } else {
                            setStateContacts(true)
                        }
                    } else {
                        navigateTo('login')
                    }
                })
                .catch((error) => {
                    // console.log(error)
                })
            }
        } else {
            navigateTo('login')
        }
    }, [])

    useEffect(() => {
        if ( id === 'group') {
            setStateContacts(false)
        } else {
            setStateContacts(true)
        }
    }, [id])

    const changeBlock = (path) => {
        // setStateContacts(boolean)
        navigateTo(`${process.env.REACT_APP_URL_GROUPS}/${path}`)
    }

    return (
        <div className='HeaderPage'>
            <Header/>

            {/* <div className='divButtonContactsGroup'>
                <Button onClick={() => changeBlock('contact')}    style={{ 
                        marginRight: '10px', 
                        color: 'white', 
                        // textDecoration: stateContacts ? 'underline white' : 'none', 
                        textShadow: stateContacts ? '0 0 10px white' : 'none',
                        border: stateContacts ? '2px solid white' : 'none',
                        boxShadow: stateContacts ? '0 0 10px white' : 'none',
                    }}>
                    Контакты
                </Button>
                <Button onClick={() => changeBlock('group')}     style={{ 
                        marginLeft: '10px', 
                        color: 'white', 
                        // textDecoration: !stateContacts ? 'underline white' : 'none', 
                        textShadow: !stateContacts ? '0 0 10px white' : 'none',
                        border: !stateContacts ? '2px solid white' : 'none',
                        boxShadow: !stateContacts ? '0 0 10px white' : 'none',
                    }}>
                    Группы контактов
                </Button>
            </div> */}
            <HeaderGroupContact stateContacts={stateContacts}/>

            <div style={{marginLeft: '250px'}}>
                {stateContacts ? (
                    <Contacts setStateContacts={setStateContacts}/>
                ) : (
                    <Groups/>
                )}
            </div>
        </div>
    );
};

export default GroupsContacts;

import React, {useState, useEffect} from 'react';
import './FormMailing.css'
import axios from 'axios';
import {
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Table,
    TableBody
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {display} from '@mui/system';

import FormTextSms from '../../FormTextSms/FormTextSms';

const url = process.env.REACT_APP_SERVER_URL;

const FormMailing = () => {
        const [text, setText] = useState('');
        const [groupId, setGroupId] = useState([]);
        const [color, setColor] = useState('');
        const [message, setMessage] = useState('');
        const [listGroup, setListGroup] = useState([]);
        const [stateButtonSend, setStateButtonSend] = useState(true);
        const [showGroupDetails, setShowGroupDetails] = useState(false);
        const [groupDetails, setGroupDetails] = useState([]);

        useEffect(() => {
            axios.get(`${url}/group/all`, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        setListGroup(response.data)
                    } else {
                        setColor('red')
                        setMessage('Произошла ошибка, попробуйте позже')
                        setTimeout(() => {
                            setMessage('')
                        }, 5000)
                    }
                })
                .catch((error) => {
                    setColor('red')
                    setMessage('Произошла ошибка, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                })
        }, []);

        const updateGroupDetails = (groupId) => {
            axios.post(`${url}/contacts/all`, {
                    selectedGroup: [...groupId],
                },
                {
                    headers: {
                        'jwt-token': localStorage?.access,
                    }
                }).then((response) => {
                if (response.status === 200 && response.data && response.data.allContact) {
                    setGroupDetails(response.data.allContact);
                } else {
                    setColor('red')
                    setMessage('Произошла ошибка, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                }
            })
                .catch((error) => {
                    setColor('red')
                    setMessage('Произошла ошибка, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                })
        }

        const newMailing = (event) => {
            event.preventDefault();
            if (!groupId) {
                setColor('red')
                setMessage('Выберите группу для рассылки')
                setTimeout(() => {
                    setMessage('')
                }, 5000)
                return
            }

            if (!text) {
                setColor('red')
                setMessage('Напишите текст сообщения')
                setTimeout(() => {
                    setMessage('')
                }, 5000)
                return
            }
            axios.post(`${url}/sendingMessage/new`, {text, groupId}, {
                headers: {
                    'jwt-token': localStorage?.access,
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        setColor('green')
                        setMessage(response.data.message)
                        setTimeout(() => {
                            setMessage('')
                        }, 10000)
                        setText('')
                        setGroupId([])
                    } else {
                        setColor('orange')
                        setMessage(response.data.message)
                        setTimeout(() => {
                            setMessage('')
                        }, 10000)
                        setText('')
                        setGroupId([])
                    }
                })
                .catch((err) => {
                    // console.log(err)
                    setColor('red')
                    setMessage('Произошла ошибка, попробуйте позже')
                    setTimeout(() => {
                        setMessage('')
                    }, 5000)
                })
        }

        return (
            <div style={{position: 'relative', marginTop: '60px'}}>
                {message ? <h3 style={{color: color}} id='messageSms'>{message}</h3> : <></>}
                <form onSubmit={newMailing} id='formSms' autoComplete="off">
                    <h3 style={{marginTop: '1px'}}>Форма запуска рассылки</h3>

                    <FormControl variant="outlined" fullWidth margin="normal"
                                 sx={{margin: '10px', width: "50vw"}}>
                        <InputLabel>Выберите группу для рассылки</InputLabel>
                        <Select
                            label="Выберите группу для рассылки"
                            value={groupId}
                            multiple={true}
                            onChange={(e) => {
                                setGroupId([...e.target.value]);
                                if (e.target.value) {
                                    setShowGroupDetails(true);
                                    updateGroupDetails(e.target.value);
                                } else {
                                    setShowGroupDetails(false);
                                }
                            }
                            }
                        >
                            {listGroup.sort((a, b) => {
                                if (a.title > b.title) {
                                    return 1;
                                }
                                if (a.title < b.title) {
                                    return -1;
                                }
                                return 0;
                            }).map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {showGroupDetails && <Accordion variant="outlined" fullWidth margin="normal" visibility="hidden"
                                                    sx={{margin: '10px', width: "50vw"}} id="groupDetailsList">
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon/>}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>Посмотреть состав групп ({
                                groupDetails.length
                            })</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow class="table_row_header">
                                            <TableCell>Имя</TableCell>
                                            <TableCell>Группа</TableCell>
                                            <TableCell>Номер</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            groupDetails.map((contact) => (
                                                <TableRow key={contact.id}>
                                                    <TableCell>{contact.name}</TableCell>
                                                    <TableCell>
                                                        {contact.group && contact.group.length > 0 ? (
                                                            contact.group.map((group) => {
                                                                const groupItemSelected = groupId.find((item) => item === group.groupId);
                                                                //.log(`${contact.name} ${group.id}`)
                                                                if (groupItemSelected) {
                                                                    const groupItem = listGroup.find((itemList) => itemList.id === group.groupId);
                                                                    console.log(`${contact.name} ${groupItem.title}`)
                                                                    return groupItem ? (
                                                                        <div className={"rounded_tag_name"}
                                                                             key={groupItem.id}>{groupItem.title}</div>
                                                                    ) : null;
                                                                }
                                                            })
                                                        ) : (
                                                            <span>No groups available</span>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>{contact.phone}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                    }

                    <FormTextSms setText={setText} setStateButtonSend={setStateButtonSend} text={text}/>

                    <Button type="submit" sx={{margin: '20px'}} variant="contained" color="primary"
                            disabled={!stateButtonSend}>
                        Запустить рассылку
                    </Button>

                </form>
            </div>
        );
    }
;

export default FormMailing;
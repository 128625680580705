import React, {useEffect, useState} from 'react';
import Header from '../Header/Header';
import './Users.css'
import ListUser from './ListUser/ListUser';
import axios from 'axios';
import UserForm from './UserForm/UserForm';
import { useNavigate } from 'react-router-dom';

const url = process.env.REACT_APP_SERVER_URL;

const Users = () => {
    const [usersList, setUsersList] = useState([]);
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('');

    let navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(`${process.env.REACT_APP_FRONTEND_PATH}/${path}`, { replace: true })
    }

    useEffect(() => {
        if(localStorage?.expire) {
            const nowDate = Math.floor(Date.now() / 1000);
            if(localStorage?.expire < nowDate){
                navigateTo('login')
            } else {
                axios.post(`${url}/login/token`, localStorage)
                .then((response) => { 
                    if (response.data) {
                        updateListUser()
                    } else {
                        navigateTo('login')
                        // console.log('токена нет на сервере')
                    }
                })
                .catch((error) => {
                    // console.log(error)
                })
            }
        } else {
            navigateTo('login')
            // console.log('нет токена на клиенте')
        }
    }, [])

    const updateListUser = () => {
        axios.get(`${url}/accounts/listUser`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then((response) => { 
            if ( response.status === 201) {
                setUsersList(response.data)
            } else {
                setColor('red')
                setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
                setTimeout(()=> {
                    setMessage('')
                }, 5000)        
            }
        })
        .catch((error) => {
            setColor('red')
            setMessage('Произошла ошибка при выгрузке пользователей, попробуйте позже')
            setTimeout(()=> {
                setMessage('')
            }, 5000)
        })
    }

    return (
        <div className='HeaderPage'>
            <Header/>
            <div className='UserForm'>
                <UserForm updateListUser={updateListUser}/>
                {message ? <h3 style={{color: color}}>{message}</h3> : <></>}
                <ListUser usersList={usersList} updateListUser={updateListUser} message={message}/>
            </div>
        </div>
    );
};

export default Users;
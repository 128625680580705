import React, { useState }  from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, TextField
  } from '@mui/material'; 
import { memo } from 'react';
import axios from 'axios';

const url = process.env.REACT_APP_SERVER_URL;

const ListUser = ({ usersList, updateListUser }) => {
    const [DeleteItem, setDeleteItem] = useState(null);
    const [PasswordItem, setPasswordItem] = useState(null);
    const [editItem, setEditItem] = useState(null);
    const [NewPassword, setNewPassword] = useState('');
    const [Message, setMessage] = useState('');
    const [Color, setColor] = useState('');
    const [editedData, setEditedData] = useState({
        login: '',
      });

    const deleteUser = (user) => {
        setDeleteItem(null);
        axios.delete(`${url}/accounts/${user.id}`, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then(response => {
            if(response.status === 201) {
                setColor('green')
                setMessage(response.data.message)
                updateListUser()
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            } else {
                setColor('orange')
                setMessage(response.data.message)
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            }
        })
        .catch(error => {
            setColor('red')
            setMessage(error.response.data.err)
            setTimeout(()=> {
                setMessage('')
            }, 5000)
        });
    }

    const saveEdit = () => {
        axios.post(`${url}/accounts/editUser`, {...editedData, userId: editItem.id}, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then(response => {
            if(response.status === 201) {
                setColor('green')
                setMessage(response.data.message)
                updateListUser()
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            } else {
                setColor('orange')
                setMessage(response.data.message)
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            }
        })
        .catch(error => {
            setColor('red')
            setMessage(error.response.data.err)
            setTimeout(()=> {
                setMessage('')
            }, 5000)
        })

        setEditItem(null);
    };

    const handleEdit = (item) => {
        setEditItem(item)
        setEditedData({login: item.login })
    }

    const editPassword = () => {
        axios.post(`${url}/accounts/newPassword`, {id: PasswordItem.id, NewPassword}, {
            headers: {
                'jwt-token': localStorage?.access,
            }
        })
        .then(response => {
            if(response.status === 201) {
                setColor('green')
                setMessage(response.data.message)
                updateListUser()
                setNewPassword('')
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            } else {
                setColor('orange')
                setMessage(response.data.message)
                setTimeout(()=> {
                    setMessage('')
                }, 5000)
            }
        })
        .catch(error => {
            setColor('red')
            setMessage(error.response.data.err)
            setTimeout(()=> {
                setMessage('')
            }, 5000)
        })
        setPasswordItem(null)
    }

    return (
        <div style={{margin: '1vw', marginTop: "20px"}} className='UsersList'>
            {Message ? <h3 style={{color: Color}}>{Message}</h3> : <></>}
            {usersList?.length ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Логин</TableCell>
                                    <TableCell>Редактировать</TableCell>
                                    <TableCell>Пароль</TableCell>
                                    <TableCell>Удалить</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usersList.map((item) =>  {
                                    return (
                                        <TableRow key={item.id}>
                                        <TableCell>{item.login}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleEdit(item)}>Изменить</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => setPasswordItem(item)}>Новый пароль</Button>
                                        </TableCell>
                                        <TableCell>
                                            {item.status === 0 ? (
                                            <Button onClick={() => setDeleteItem(item)}>Удалить</Button>
                                            ) : (
                                                <></>
                                            )}
                                        </TableCell>
                                        </TableRow>
                                    ) 
                                }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog open={Boolean(DeleteItem)} onClose={() => setDeleteItem(null)}>
                        <DialogTitle>Вы точно хотите удалить {DeleteItem?.name}?</DialogTitle>
                        <DialogContent>
                            <DialogActions>
                            <Button onClick={() => setDeleteItem(null)}>Нет</Button>
                            <Button onClick={() => deleteUser(DeleteItem)} color="error">
                                Да
                            </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>

                    <Dialog open={Boolean(editItem)} onClose={() => setEditItem(null)}>
                        <DialogTitle>Редактировать данные {editItem?.name}</DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Логин"
                                type='text'
                                value={editedData.login}
                                onChange={(e) => setEditedData({ ...editedData, login: e.target.value })}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setEditItem(null)}>Отмена</Button>
                            <Button onClick={saveEdit} color="primary">
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Dialog open={Boolean(PasswordItem)} onClose={() => setPasswordItem(null)}>
                        <DialogTitle>Задать новый пароль для {PasswordItem?.name}</DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Пароль"
                                type='text'
                                value={NewPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setPasswordItem(null)}>Отмена</Button>
                            <Button onClick={editPassword} color="primary">
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>
                
                </>
            ) : (
                <h3>Список пользователей пусть, создайте их в форме выше</h3>
            )}
        </div>
    );
};

export default memo(ListUser);